import React from 'react';
import Navbar from '../components/navbar'
import Footer from '../components/footer'

const BuyingGuide = () => {
    return (
        <div>
            <Navbar />
            <div className="buying-guide-container">
                <h1>Welcome to MAK Luxury Real Estate's Buying Guide</h1>
                <p>
                    Congratulations on considering to invest in luxury real estate with MAK
                    Luxury Real Estate in Dubai! We're here to guide you through the
                    process and help you make informed decisions.
                </p>
                <div className="section pt-5">
                    <h2>Step 1: Define Your Requirements</h2>
                    <p>
                        Begin by identifying your preferences and requirements for your dream
                        luxury property in Dubai. Consider factors such as location, budget,
                        amenities, and property type.
                    </p>
                </div>
                <div className="section">
                    <h2>Step 2: Research Properties</h2>
                    <p>
                        Explore our curated selection of luxury properties in Dubai. Our
                        listings feature a wide range of exquisite properties tailored to
                        meet the diverse needs of our clients.
                    </p>
                </div>
                <div className="section">
                    <h2>Step 3: Schedule Property Viewings</h2>
                    <p>
                        Contact our experienced real estate agents to schedule viewings of the
                        properties that interest you. We'll arrange convenient viewing times
                        and provide you with detailed information about each property.
                    </p>
                </div>
                <div className="section">
                    <h2>Step 4: Make an Offer</h2>
                    <p>
                        Once you've found your ideal luxury property, our team will assist you
                        in making a competitive offer. We'll negotiate on your behalf to
                        ensure you get the best deal possible.
                    </p>
                </div>
                <div className="section">
                    <h2>Step 5: Closing the Deal</h2>
                    <p>
                        After your offer is accepted, we'll guide you through the closing
                        process, including paperwork, inspections, and any necessary legal
                        procedures. Our goal is to make the buying process as smooth and
                        stress-free as possible for you.
                    </p>
                </div>
                <div className="section">
                    <h2>Step 6: Enjoy Your New Luxury Property</h2>
                    <p>
                        Congratulations! You are now the proud owner of a luxurious property
                        in Dubai. We hope you enjoy your new home and the unparalleled
                        lifestyle that comes with it.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BuyingGuide;