import React from 'react'

function CallAction() {
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="bg-light rounded p-3">
                        <div className=" rounded p-4" style={{ backgroundColor: '#fff' }}>
                            <div className="row g-5 align-items-center">
                                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                    <img className="img-fluid rounded w-100" src="img/call-to-action.jpg" alt="" />
                                </div>
                                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                    <div className="mb-4">
                                        <h1 className="mb-3">Contact With Our Certified Agent</h1>
                                        <p>mpower Your Home Search with Expert Guidance. Contact Our Agents to Elevate Your Real Estate Experience - Uncover Your Dream Home Today!</p>
                                    </div>
                                    <a href="tel:+971525575307" className="btn btn-primary py-3 px-4 me-2"><i
                                        className="fa fa-phone-alt me-3"></i>Make A Call</a>
                                    <a href="/contact" className="btn btn-glass-appointment py-3 px-4 pt-3"><i
                                        className="fa fa-calendar-alt me-3"></i>Get Appoinment</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallAction