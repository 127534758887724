
import Navbar from '../components/navbar'
import Header from '../components/header'
import SearchBar from '../components/search-bar'
import PropertyTypeCard from '../components/property-type-card'
import AboutSection from '../components/about-section'
import PropertyCard from '../components/property-card'
import CallAction from '../components/CallAction'
import TeamSection from '../components/team-section'
import Footer from '../components/footer'
import TopPlaces from '../components/topPlaces'

function home() {
  return (
    <div className='contaner-xxl'>
      <Navbar></Navbar>
      <Header></Header>
      <SearchBar></SearchBar>
      <PropertyCard></PropertyCard>
      <AboutSection></AboutSection>

      <PropertyTypeCard></PropertyTypeCard>
      <CallAction></CallAction>

      <Footer></Footer>
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
    </div>
  )
}

export default home