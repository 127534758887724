import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'


function PeropertyDetail() {

  const params = useParams()
  const [property, setProperty] = useState([])
  const url = 'https://mak-admin.onrender.com/properties/' + params.id
  console.log(url)

  const getProperty = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    await axios.get('https://mak-admin.onrender.com/properties/' + params.id, headers).then(res => {
      console.log(res.data.data);
      setProperty(res.data.data);
    })
  }

  useEffect(() => {
    getProperty()
  }, [])

  return (


    <div className=''>

      <Navbar />

      {
        property.map((property) => {
          return <div>
            <div className="detail-header ">
              <div className="prob-detail">
                <div className="prop-title wow fadeInLeft " data-wow-delay="0.1s">
                  <h1 className=" text-start animated fadeIn">{property.name}</h1>
                  <nav aria-label="breadcrumb animated fadeIn">
                    <ol className="breadcrumb text-uppercase">
                      <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                      <li className="breadcrumb-item"><a href="#">Ready to Move</a></li>
                      <li className="breadcrumb-item text-body active" aria-current="page">{property.name}</li>
                    </ol>
                  </nav>
                  <h3 style={{ paddingTop: 100 }}>Descrption</h3>
                  <p style={{ paddingRight: 20 }}>{property.overview}</p>
                </div>
                <div className="image-prop wow fadeInRight " data-wow-delay="0.1s">
                  <div className="imgPrp">
                    <a href="https://www.coohom.com/pub/tool/panorama/show?obsPlanId=3FO3XKEPHH6L&locale=en_US&utm_source=light720_share&utm_medium=linkcopy&utm_content=3FO3XKEPHH6L">
                      <img className="img-fluid mt-5" src={property.viewTour} alt="" />
                    </a>
                    <div className="info">
                      <div className="rightList">
                        <p><i className="fa-solid fa-coins text-primary me-2"></i>{property.startingPrice} </p>
                        <p><i className="fa fa-map-marker-alt text-primary me-2"></i>{property.location} </p>
                        <p><i className="fa fa-ruler-combined text-primary me-2"></i>{property.size}</p>
                        <p><i className="fa fa-bed text-primary me-2"></i>{property.uniteType}</p>
                        <p><i className="fa fa-bed text-primary me-2"></i>Garden</p>
                        <p><i className="fa fa-bed text-primary me-2"></i>Kids Play Erea</p>
                      </div>
                      <div className="leftList">
                        <p><i className="fa-solid fa-parking text-primary me-2"></i>1 Parking included</p>
                        <p><i className="fa fa-layer-group text-primary me-2"></i>20 Floor </p>
                        <p><i className="fa fa-cable-car text-primary me-2"></i>24 Hour Security</p>
                        <p><i className="fa fa-cable-car text-primary me-2"></i>GYM</p>
                        <p><i className="fa fa-cable-car text-primary me-2"></i>Swimming Pool</p>
                        <p><i className="fa fa-cable-car text-primary me-2"></i>C C T V</p>
                      </div>
                    </div>
                  </div>
                  <div className="priceLoc">
                    <h3>Descrption :</h3>
                    <p>{property.overviwe}</p>

                  </div>
                </div>
              </div>
            </div>

            <div className="titleSlider text-center my-4 ">
              <h1>Gallery</h1>
            </div>

            <div id="slidershowpro">
              <figure>
                <img src={property.gallery[0]} />
                <img src={property.gallery[1]} />
                <img src={property.gallery[2]} />
                <img src={property.gallery[3]} />
                <img src={property.gallery[4]} />
              </figure>
            </div>

            <div className="proDetails wow fadeInUp " data-wow-delay="0.1s">

              <div className="righproDetails wow fadeInLeft " data-wow-delay="0.1s">
                <h4 className="wow fadeInUp " data-wow-delay="0.1s">MAK Luxury Real Estate Offers This amazing {property.uniteType} Apartments
                  At {property.name}.
                </h4>

                <h5 className="pt-5 wow fadeInLeft " data-wow-delay="0.1s">PROPERTY DETAILS</h5>
                <p className="pt-2 wow fadeInLeft " data-wow-delay="0.1s">{property.overview}</p>





                <ul className='mt-3'>
                  <li>{property.hightlights[0]}</li>
                  <li>{property.hightlights[1]}</li>
                  <li>{property.hightlights[2]}</li>
                  <li>{property.hightlights[3]}</li>
                  <li>{property.hightlights[4]}</li>
                  <li>{property.hightlights[5]}</li>
                </ul>
                <h5 className="pt-2 wow fadeInLeft " data-wow-delay="0.1s">LOCATION FEATURES</h5>
                <ul>
                  <li>{property.locationFeaturs[0]}</li>
                  <li>{property.locationFeaturs[1]}</li>
                  <li>{property.locationFeaturs[2]}</li>
                  <li>{property.locationFeaturs[3]}</li>
                  <li>{property.locationFeaturs[4]}</li>
                </ul>

                <div className="mapSection wow fadeInLeft " data-wow-delay="0.1s">
                  <div className="mapView">
                    <h5>Location</h5>
                    <div className="locationCard wow fadeInLeft " data-wow-delay="1s">
                      <a href={property.mapTag}><img src="img/map.jpg" alt="" /></a>
                      <div className="mapInfo">
                        <a href={property.mapTag}>
                          <p> {property.location} </p>
                          <p> United Arab Emriats</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="agentDetail wow fadeInRight " data-wow-delay="1s">
                    <h5>Agent Details</h5>
                    <div className="agentCard">
                      <a href=""><img src="img/team-4.jpg" alt="" /></a>
                      <div className="agentInfo">
                        <a href=""><p>Hakim Cheihk</p></a>
                        <p> Real Estate Agent
                          <br /> <span> (196 properties) </span> </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="wow fadeInLeft text-white" data-wow-delay="0.1s" style={{ fontWeight: 400 }}>Like this property? Come back to it later, easily.</h5>
                <div className="shareList wow fadeInLeft " data-wow-delay="0.1s">
                  <a href="" className="btn saveBtn"><i className="fa fa-heart"> </i>Save to Shortlist </a>
                  <a href="" className="btn shareBtn"><i className="fa-brands fa-facebook">  </i>Facebook</a>
                  <a href="" className="btn shareBtn"><i className="fa-brands fa-instagram"> </i>Instagram </a>
                  <a href="" className="btn shareBtn"><i className="fa-brands fa-x-twitter">  </i>X - Twitter</a>
                </div>
              </div>

              <div className="leftproDetail wow fadeInRight " data-wow-delay="0.1s">

                <div className="priceagent wow fadeInRight " data-wow-delay="0.1s">
                  <p>STARTING PRICE</p>
                  <h2>{property.startingPrice}</h2>

                  <div className="callmessaageAction wow fadeInRight " data-wow-delay="0.1s">
                    <a className="btn callButton" href='tel:+971525575307'><i className="fa fa-phone"></i> Call </a>
                    <a className="btn emailButton" href='mailto:info@mak-luxury.com'><i className="fa-solid fa-envelope"></i> Email </a>
                    <a className="btn whatsappButton" href='https://wa.me/971525575307'><i className="fa-brands fa-whatsapp"></i> Whatsapp </a>
                  </div>
                  <div className="agentReplis wow fadeInRight " data-wow-delay="0.1s">
                    <img src="/img/team-4.jpg" alt="" />
                    <p>Hakim usually responds within 5 minutes</p>
                  </div>
                  <a className="btn saveBtn mt-4 mb-5 wow fadeInDoRight " data-wow-delay="0.1s"> <i
                    className="fa fa-heart"></i> Save to Shortlist </a>
                </div>

                <h5 className="my-4">Files</h5>


                <div className='downloads'>
                  <a href={property.brocher} className="btn btn-primary " style={{ margin: 10 }}>Download Brochure</a>
                  <a href={property.brocher} className="btn btn-primary " style={{ margin: 10 }}>Download Payment Plan</a>
                  <a href={property.brocher} className="btn btn-primary " style={{ margin: 10 }}>Download Floor Plan</a>

                </div>

                <div className="whereLive wow fadeInRight " data-wow-delay="0.1s">
                  <div>
                    <p>Not sure where to live?
                      <br /> <span>Find your Suitable Place</span></p>
                  </div>
                  <a href="" className="btn btn-primary mt-5">Book a Free Consltation</a>
                </div>

              </div>
            </div>

            <div className="morePropBg">

              <div className="titleSlider pt-2 pl-5 ">
                <h5> More available in the same area </h5>
              </div>

              <div className="moreProp">




                <div className="emailNotf">
                  <img src="/img/Group 6.gif" alt="" />
                  <h5> Don't miss out! </h5>
                  <p>Get notified with new properties for</p>
                  <h5 style={{ color: '#000' }}> {property.location} </h5>
                  <div className="notfInput">
                    <input type="text" className="form-notf" placeholder="Enter your Whatsapp Number" />
                    <a href="" className="btn notBtn"> Notify me </a>
                  </div>
                </div>

              </div>

            </div>
          </div>
        })
      }


      <Footer />
    </div>
  )
}




export default PeropertyDetail