import React from 'react'
import Navbar from '../components/navbar'
import SmallHeader from '../components/SmallHeader'
import AboutSection from '../components/about-section'
import CallAction from '../components/CallAction'
import TeamSection from '../components/team-section'
import Footer from '../components/footer'

function about() {
  return (
    <div>
    <Navbar></Navbar>
    <SmallHeader></SmallHeader>
    <AboutSection></AboutSection>
    <CallAction></CallAction>
    <TeamSection></TeamSection>
    <Footer></Footer>
    <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>
 </div>
  )
}

export default about