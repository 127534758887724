import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Filter from '../components/filter'
import SearchBar from '../components/search-bar'
import axios from 'axios';


function AllProperties() {

    const [properties, setProperties] = useState([])
    const [readyToMove, setReadyToMove] = useState([])
    const [rent, setForRent] = useState([])
    const [offPlan, setOffPlan] = useState([])


    const getProperties = async () => {
        const headers = {
            "Content-Type": "application/json",
        };
        await axios.get('https://mak-admin.onrender.com/properties', { headers }).then(res => {
            console.log(res.data.data);
            setProperties(res.data.data);
        })
    }


    const getReadyProperties = async () => {
        const headers = {
            "Content-Type": "application/json",
        };
        await axios.get('https://mak-admin.onrender.com/readyToMove', { headers }).then(res => {
            console.log(res.data.data);
            setReadyToMove(res.data.data);
        })
    }

    const getRantProperties = async () => {
        const headers = {
            "Content-Type": "application/json",
        };
        await axios.get('https://mak-admin.onrender.com/rent', { headers }).then(res => {
            console.log(res.data.data);
            setForRent(res.data.data);
        })
    }

    const getOffPlan = async () => {
        const headers = {
            "Content-Type": "application/json",
        };
        await axios.get('https://mak-admin.onrender.com/offPlan', { headers }).then(res => {
            console.log(res.data.data);
            setOffPlan(res.data.data);
        })
    }


    useEffect(() => {
        getProperties();
        getReadyProperties();
        getRantProperties();
        getOffPlan()
    }, [])

    return (
        <div>
            <Navbar />
            <Filter />
            <div className='mx-5 '>
                <nav aria-label="breadcrumb animated fadeIn">
                    <ol className="breadcrumb text-uppercase">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="/all-properties">Browse Properties</a></li>

                    </ol>
                </nav>
            </div>
            <div className='mx-5 my-5'>
                <div className="container-xxl-list py-5">
                    <div className="container-list ">

                        <div className="property-list">

                            <div className=" container-tabs row ">
                                <div className="col-lg-6">
                                    <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                                        <h1 className="mb-3">Property Listing</h1>
                                    </div>
                                </div>

                                <div className="col-lg-6 text-start text-lg-end wow slideInRight" data-wow-delay="0.1s">
                                    <ul className="nav nav-pills d-inline-flex justify-content-end mb-5">
                                        <li className="nav-item me-2 mb-2">
                                            <a className="btn btn-outline-primary active" data-bs-toggle="pill" href="#all">All
                                                Properties</a>
                                        </li>
                                        <li className="nav-item me-2 mb-2">
                                            <a className="btn btn-outline-primary" data-bs-toggle="pill" href="#selling">For
                                                Sell</a>
                                        </li>
                                        <li className="nav-item me-2">
                                            <a className="btn btn-outline-primary" data-bs-toggle="pill" href="#buy">For Rent</a>
                                        </li>
                                        <li className="nav-item me-2">
                                            <a className="btn btn-outline-primary" data-bs-toggle="pill" href="#off">Off Plan</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content overflow-hidden">


                                <div id="all" className="tab-pane fade show p-0 active">
                                    <div className="row g-4">


                                        {
                                            properties.map((properties) => {
                                                return <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                                    <div className="property-item rounded overflow-hidden">
                                                        <div className="position-relative overflow-hidden">

                                                            <a href={properties._id}><img className="img-fluid" src={properties.gallery[0]} alt="" style={{ height: 290, width: 500, objectFit: "cover" }} /></a>
                                                            <div
                                                                className="btn-outline-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                                                                For Sell</div>
                                                            <div
                                                                className="bg-glass rounded-top text-white position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                                                                {properties.name}</div>
                                                        </div>
                                                        <div className="card-glass px-4 ">
                                                            <h5 className="pt-4" style={{ color: '#000' }}>Down Payment {properties.downPyment} </h5>

                                                            <p><i className="fa fa-map-marker-alt me-2" style={{ color: 'grey' }}></i>{properties.location}</p>
                                                            <p><i className="fa fa-house me-2" style={{ color: 'grey' }}></i>{properties.uniteType}</p>
                                                            <p><i className="fa fa-calendar-days me-2" style={{ color: 'grey' }}></i>Payment Plan {properties.paymentPlan}</p>
                                                            <p><i className="fa fa-house me-2 mb-4" style={{ color: 'grey' }}></i>{properties.category}</p>
                                                        </div>
                                                        <div className="card-glass border-top text-center" style={{ height: '40' }}>
                                                            <p style={{ fontSize: 12, marginTop: 12 }}>STARTING PRICE</p>
                                                            <h5 className="" style={{ color: '#000', fontSize: 22 }}>{properties.startingPrice}</h5>

                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }


                                    </div>
                                </div>



                                <div id="selling" className="tab-pane fade show p-0">
                                    <div className="row g-4">
                                        {
                                            readyToMove.map((properties) => {
                                                return <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                                    <div className="property-item rounded overflow-hidden">
                                                        <div className="position-relative overflow-hidden">

                                                            <a href={properties._id}><img className="img-fluid" src={properties.gallery[0]} alt="" style={{ height: 290, width: 500, objectFit: "cover" }} /></a>
                                                            <div
                                                                className="btn-outline-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                                                                For Sell</div>
                                                            <div
                                                                className="bg-glass rounded-top text-white position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                                                                {properties.name}</div>
                                                        </div>
                                                        <div className="card-glass px-4 ">
                                                            <h5 className="pt-4" style={{ color: '#000' }}>Down Payment {properties.downPyment} </h5>

                                                            <p><i className="fa fa-map-marker-alt me-2" style={{ color: 'grey' }}></i>{properties.location}</p>
                                                            <p><i className="fa fa-house me-2" style={{ color: 'grey' }}></i>{properties.uniteType}</p>
                                                            <p><i className="fa fa-calendar-days me-2" style={{ color: 'grey' }}></i>Payment Plan {properties.paymentPlan}</p>
                                                            <p><i className="fa fa-house me-2 mb-4" style={{ color: 'grey' }}></i>{properties.category}</p>
                                                        </div>
                                                        <div className="card-glass border-top text-center" style={{ height: '40' }}>
                                                            <p style={{ fontSize: 12, marginTop: 12 }}>STARTING PRICE</p>
                                                            <h5 className="" style={{ color: '#000', fontSize: 22 }}>{properties.startingPrice}</h5>

                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }


                                    </div>
                                </div>



                                <div id="buy" className="tab-pane fade show p-0">
                                    <div className="row g-4">
                                        {
                                            rent.map((properties) => {
                                                return <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                                    <div className="property-item rounded overflow-hidden">
                                                        <div className="position-relative overflow-hidden">

                                                            <a href={properties._id}><img className="img-fluid" src={properties.gallery[0]} alt="" style={{ height: 290, width: 500, objectFit: "cover" }} /></a>
                                                            <div
                                                                className="btn-outline-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                                                                For Sell</div>
                                                            <div
                                                                className="bg-glass rounded-top text-white position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                                                                {properties.name}</div>
                                                        </div>
                                                        <div className="card-glass px-4 ">
                                                            <h5 className="pt-4" style={{ color: '#000' }}>Down Payment {properties.downPyment} </h5>

                                                            <p><i className="fa fa-map-marker-alt me-2" style={{ color: 'grey' }}></i>{properties.location}</p>
                                                            <p><i className="fa fa-house me-2" style={{ color: 'grey' }}></i>{properties.uniteType}</p>
                                                            <p><i className="fa fa-calendar-days me-2" style={{ color: 'grey' }}></i>Payment Plan {properties.paymentPlan}</p>
                                                            <p><i className="fa fa-house me-2 mb-4" style={{ color: 'grey' }}></i>{properties.category}</p>
                                                        </div>
                                                        <div className="card-glass border-top text-center" style={{ height: '40' }}>
                                                            <p style={{ fontSize: 12, marginTop: 12 }}>STARTING PRICE</p>
                                                            <h5 className="" style={{ color: '#000', fontSize: 22 }}>{properties.startingPrice}</h5>

                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }


                                    </div>
                                </div>


                                <div id="off" className="tab-pane fade show p-0">
                                    <div className="row g-4">
                                        {
                                            offPlan.map((properties) => {
                                                return <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                                    <div className="property-item rounded overflow-hidden">
                                                        <div className="position-relative overflow-hidden">

                                                            <a href={properties._id}><img className="img-fluid" src={properties.gallery[0]} alt="" style={{ height: 290, width: 500, objectFit: "cover" }} /></a>
                                                            <div
                                                                className="btn-outline-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                                                                For Sell</div>
                                                            <div
                                                                className="bg-glass rounded-top text-white position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                                                                {properties.name}</div>
                                                        </div>
                                                        <div className="card-glass px-4 ">
                                                            <h5 className="pt-4" style={{ color: '#000' }}>Down Payment {properties.downPyment} </h5>

                                                            <p><i className="fa fa-map-marker-alt me-2" style={{ color: 'grey' }}></i>{properties.location}</p>
                                                            <p><i className="fa fa-house me-2" style={{ color: 'grey' }}></i>{properties.uniteType}</p>
                                                            <p><i className="fa fa-calendar-days me-2" style={{ color: 'grey' }}></i>Payment Plan {properties.paymentPlan}</p>
                                                            <p><i className="fa fa-house me-2 mb-4" style={{ color: 'grey' }}></i>{properties.category}</p>
                                                        </div>
                                                        <div className="card-glass border-top text-center" style={{ height: '40' }}>
                                                            <p style={{ fontSize: 12, marginTop: 12 }}>STARTING PRICE</p>
                                                            <h5 className="" style={{ color: '#000', fontSize: 22 }}>{properties.startingPrice}</h5>

                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }


                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AllProperties