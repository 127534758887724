import React from 'react'

function TeamSection() {
    return (
        <div>
            <div className="morePropBg py-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
                        <h1 className="mb-3">Property Agents</h1>
                        <p>Meet Our Dynamic Team of Real Estate Experts – Your Partners in Property Success. Find the Perfect Agent to Guide You Through Your Home Journey!</p>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="team-item rounded overflow-hidden">
                                <div className="position-relative">
                                    <img className="img-fluid" src="img/team-2.JPG" alt="" style={{ height: 310, width: 500, objectFit: "cover" }} />
                                    <div
                                        className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                        <a className="btn btn-square mx-1" href="https://wa.me/+971525575307"><i className="fab fa-whatsapp"></i></a>
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                                <div className="text-center p-4 mt-3">
                                    <h5 className="fw-bold mb-0">Makhlouf Kardamouche</h5>
                                    <small>CEO</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="team-item rounded overflow-hidden">
                                <div className="position-relative">
                                    <img className="img-fluid" src="img/cha.jpeg" alt="" style={{ height: 310, width: 500, objectFit: "cover" }} />
                                    <div
                                        className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-whatsapp"></i></a>
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                                <div className="text-center p-4 mt-3">
                                    <h5 className="fw-bold mb-0">Zahraoui Chahinaz</h5>
                                    <small>General Manager</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="team-item rounded overflow-hidden">
                                <div className="position-relative">
                                    <img className="img-fluid" src="img/al.jpeg" alt="" style={{ height: 310, width: 500, objectFit: "cover" }} />
                                    <div
                                        className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-whatsapp"></i></a>
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                                <div className="text-center p-4 mt-3">
                                    <h5 className="fw-bold mb-0">Alaa Bennaceur</h5>
                                    <small>Sales Advisor</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="team-item rounded overflow-hidden">
                                <div className="position-relative">
                                    <img className="img-fluid" src="img/is.jpeg" alt="" style={{ height: 310, width: 500, objectFit: "cover" }} />
                                    <div
                                        className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-whatsapp"></i></a>
                                        <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                                <div className="text-center p-4 mt-3">
                                    <h5 className="fw-bold mb-0">Tazroute Ismail</h5>
                                    <small>Marketing Specialist</small>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


        </div>
    )
}

export default TeamSection