import React from 'react'
import Navbar from '../components/navbar'
import SmallHeader from '../components/SmallHeader'
import ContactSection from '../components/ContactSection'
import CallAction from '../components/CallAction'
import Footer from '../components/footer'

function contact() {
  return (
    <div className='contaner-xxl' id="home-screen">
      <Navbar></Navbar>

      <ContactSection></ContactSection>
      <CallAction></CallAction>
      <Footer></Footer>
      <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>
    </div>
  )
}

export default contact