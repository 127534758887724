import React from 'react'

function Navbar() {
    return (
        <div>
            <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
                <nav className="navbar navbar-expand-lg  navbar-light py-0 px-4">
                    <a href="/" className="navbar-brand d-flex align-items-center text-center">
                        <div className=" p-2 me-2">
                            <img className="logo" src="img/logo-mak (1).png" alt="Icon" />
                        </div>
                    </a>
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto">
                            <a href="/" className="nav-item nav-link active">Home</a>
                            <a href="/about" className="nav-item nav-link">About</a>
                            <div className="nav-item dropdown">
                                <a href="/all-properties" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Property</a>
                                <div className="dropdown-menu rounded-0 m-0">
                                    <a href="/all-properties" className="dropdown-item">Property for sale</a>
                                    <a href="/all-properties" className="dropdown-item">Property for rent</a>
                                    <a href="/all-properties" className="dropdown-item">Off Plan Properties</a>
                                </div>
                            </div>
                            <a href="/contact" className="nav-item nav-link">Contact</a>

                            <div id="google_translate_element" className='selectLng'></div>

                        </div>
                        <a href="/all-properties" className="btn btn-primary  px-3 d-none d-lg-flex">Find your Place</a>
                    </div>
                </nav>
            </div>

        </div>
    )
}

export default Navbar