import React from 'react'

function SearchBar() {
  return (
    <div>
        <div className="container-fluid-search mb-5 wow fadeIn" data-wow-delay="0.1s" style={{padding: 35, paddingLeft : 35}}>
            <div className="container">
                <div className="row g-2">
                    <div className="col-md-10">
                        <div className="row g-2">
                            <div className="col-md-4">
                                <input type="text" className="form-control  py-3" placeholder="Search Keyword"/>
                            </div>
                            <div className="col-md-4 wrap">
                                <select className="form-select  py-3">
                                    <option defaultValue disabled>Property Type</option>
                                    <optgroup label='Property for Sale'>
                                        <option value="">Ready To move</option>
                                        <option value="">Off Plan</option>
                                    </optgroup>
                                    <option value="2">Property For Rent</option>
                                
                                </select>
                            </div>
                            <div className="col-md-4 wrap">
                                <select className="form-select py-3">
                                    <option defaultValue disabled>Location</option>
                                    <option value="1">Dubai</option>
                                    <option value="2">Sharjah</option>
                                    <option value="3">Abu Dhabi</option>
                                    <option value="3">Ajman</option>
                                    <option value="3">Ras Al Khayma</option>
                                    <option value="3">Umm Al Quwain</option>
                                    <option value="3">Fujairah</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <a className="btn btn-dark border-0 w-100 py-3" href='/all-properties'>Search</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SearchBar