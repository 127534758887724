import React from 'react'
import { useEffect } from 'react'

import About from './screens/about'
import Home from './screens/home'
import Contact from './screens/contact'
import PropertyType from './screens/property-type'
import PeropertyDetail from './screens/peroperty-detail'
import AllProperties from './screens/all-properties'
import BuyingGuide from './screens/buyingGuid'
import TermsAndConditions from './screens/TermsAndContditon'
import { BrowserRouter, Routes, Route } from 'react-router-dom'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />

        <Route path='/property-types' element={<PropertyType />} />
        <Route path='/:id' element={<PeropertyDetail />} />
        <Route path='/all-properties' element={<AllProperties />} />
        <Route path='/buyingGuid' element={<BuyingGuide />} />
        <Route path='/terms' element={<TermsAndConditions />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App