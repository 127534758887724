import React, { useState, useEffect } from 'react'
import axios from 'axios';

function PropertyTypeCard() {

    const [readyToMove, setReadyToMove] = useState([])
    const [rent, setForRent] = useState([])
    const [offPlan, setOffPlan] = useState([])
    const getReadyCategories = async () => {
        const headers = {
            "Content-Type": "application/json",
        };
        await axios.get('https://mak-admin.onrender.com/readycategories', { headers }).then(res => {
            console.log(res.data.data);
            setReadyToMove(res.data.data);
        })
    }

    const getRantCategories = async () => {
        const headers = {
            "Content-Type": "application/json",
        };
        await axios.get('https://mak-admin.onrender.com/rentcategories', { headers }).then(res => {
            console.log(res.data.data);
            setForRent(res.data.data);
        })
    }

    const getOffPlanCategories = async () => {
        const headers = {
            "Content-Type": "application/json",
        };
        await axios.get('https://mak-admin.onrender.com/offPlancategories', { headers }).then(res => {
            console.log(res.data.data);
            setOffPlan(res.data.data);
        })
    }


    useEffect(() => {
        getOffPlanCategories();
        getRantCategories();
        getReadyCategories()
    }, [])


    return (
        <div>
            <div className="container-tabs row">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
                    <h1 className="mb-3">Property Types</h1>
                    <p>Endless Possibilities Await: Discover Ready-to-Move, For Rent, and Off-Plan Properties – Your Ideal Home Awaits with <b> MAK Luxury Real Estate. </b></p>
                </div>


                <div className="col-lg-6 text-center text-lg-center wow slideInLeft" data-wow-delay="0.1s">
                    <ul className="nav nav-pills nav-fill d-inline-flex justify-content-center mb-5">
                        <li className="nav-item me-2    my-1">
                            <a className="btn btn-outline-primary active" data-bs-toggle="pill"
                                href='#sell'>Ready to Move</a>
                        </li>
                        <li className="nav-item me-2 my-1">
                            <a className="btn btn-outline-primary" data-bs-toggle="pill"
                                href='#rent'>For Rent</a>
                        </li>
                        <li className="nav-item me-0 my-1">
                            <a className="btn btn-outline-primary" data-bs-toggle="pill"
                                href='#offPlan'>Off Plan</a>
                        </li>
                    </ul>
                </div>

                <div className="tab-content overflow-hidden" >

                    <div id="sell" className="tab-pane fade show p-0 active" >
                        <div className="row g-4">
                            {
                                readyToMove.map((category) => {

                                    return <div className=" col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <a className="cat-item d-block  text-center rounded p-3" href="/all-properties">
                                            <div className="rounded p-4" >
                                                <h6 style={{ color: '#000' }} >{category.title}</h6>

                                            </div>
                                        </a>
                                    </div>


                                })
                            }
                        </div>
                    </div>

                    <div id='rent' className="tab-pane fade show p-0 " >
                        <div className="row g-4">

                            {
                                rent.map((category) => {

                                    return <div className=" col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <a className="cat-item d-block  text-center rounded p-3" href="/all-properties">
                                            <div className="rounded p-4">
                                                <h6 style={{ color: '#000' }} >{category.title}</h6>

                                            </div>
                                        </a>
                                    </div>


                                })
                            }

                        </div>

                    </div>

                    <div id='offPlan' className="tab-pane fade show p-0 " >
                        <div className="row g-4">

                            {
                                offPlan.map((category) => {

                                    return <div className=" col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <a className="cat-item d-block  text-center rounded p-3" href="/all-properties">
                                            <div className="rounded p-4"  >
                                                <h6 style={{ color: '#000' }} >{category.title}</h6>

                                            </div>
                                        </a>
                                    </div>


                                })
                            }
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default PropertyTypeCard