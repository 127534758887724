import React from 'react'

function ContactSection() {
    return (
        <div className='pt-5'>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
                        <h1 className="mb-3">Contact Us</h1>
                        <p></p>
                    </div>
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="row gy-4">
                                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                                    <div className=" rounded p-3">
                                        <div className="d-flex align-items-center  rounded p-3"
                                        >
                                            <div className="icon me-3" style={{ width: 45, height: 45 }}>
                                                <i className="fa fa-map-marker-alt text-primary"></i>
                                            </div>
                                            <span>Office 1303, The Regal Tower, 1501 Al Mustaqbal st, Business Bay, Dubai, UAE</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                                    <div className=" rounded p-3">
                                        <div className="d-flex align-items-center  rounded p-3"
                                        >
                                            <div className="icon me-3" style={{ width: 45, height: 45 }}>
                                                <i className="fa fa-envelope-open text-primary"></i>
                                            </div>
                                            <span>info@mak-luxury.com</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                                    <div className=" rounded p-3">
                                        <div className="d-flex align-items-center rounded p-3"
                                        >
                                            <div className="icon me-3" style={{ width: 45, height: 45 }}>
                                                <i className="fa fa-phone-alt text-primary"></i>
                                            </div>
                                            <span>+ 971 (0) 52 557 5307</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <iframe className="position-relative rounded w-100 h-100"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14441.935023878203!2d55.2607477!3d25.1869036!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f697422b61b33%3A0x61dacaf45338de8c!2sMAK%20Luxury%20Real%20Estate!5e0!3m2!1sen!2sae!4v1708605596815!5m2!1sen!2sae"
                                frameborder="0" style={{ minHeight: 400 }} allowfullscreen="" aria-hidden="false"
                                tabindex="0"></iframe>
                        </div>
                        <div className="col-md-6">
                            <div className="wow fadeInUp" data-wow-delay="0.5s">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                                <label for="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="email"
                                                    placeholder="Your Email" />
                                                <label for="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                                <label for="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a message here"
                                                    id="message" style={{ height: 150 }}></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContactSection