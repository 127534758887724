import React from 'react'

function AboutSection() {
  return (
    <div>
        <div className="container-xxl px-5 py-5">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="about-img position-relative overflow-hidden p-5 pe-0">
                            <img className="img-fluid w-100" src="img/bg.jpg"/>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <h1 className="mb-4">Perfect Place To Find The Perfect Property</h1>
                        <p className="mb-4">At <b> MAK Luxury Real Estate </b>, we pride ourselves on delivering unparalleled real estate experiences. Our commitment to excellence is reflected in:</p>
                        <p><i className="fa fa-check text-primary textpo me-3"></i><b>Unmatched Expertise: </b> With a team of seasoned professionals, we bring extensive knowledge of the real estate market, ensuring you receive informed guidance at every step.</p>
                        <p><i className="fa fa-check text-primary me-3"></i> <b> Client-Centric Approach: </b> Your needs are our priority. We tailor our services to exceed your expectations, providing personalized solutions for a seamless real estate journey.</p>
                        <p><i className="fa fa-check text-primary me-3"></i> <b> Innovative Solutions: </b> Embracing cutting-edge technology and industry trends, we offer innovative solutions that redefine the real estate experience, making your property journey efficient and enjoyable.</p>
                        <p className="mb-4"> Discover the <b> MAK Luxury Real Estate </b>  difference – where expertise meets personalized service, and innovation transforms real estate.</p>
                        <a className="btn btn-primary py-3 px-5 mt-3" href="/about">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutSection