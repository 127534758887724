import React from 'react'

function Filter() {
    return (
        <div>
            <div className="container-fluid  wow fadeIn" data-wow-delay="0.1s" style={{ padding: 35, paddingLeft: 35, marginTop: 80 }}>
                <div className="container">
                    <div className="row g-2">
                        <div className="col-md-10">
                            <div className="row g-2">

                                <div className="col-md-4">
                                    <input type="text" className="form-control  py-3" placeholder="Search Keyword" />
                                </div>

                                <div className="col-md-2 wrap">
                                    <select className="form-select  py-3">
                                        <option defaultValue disabled>Property Type</option>
                                        <option value="all">All Types</option>
                                        <optgroup label='Property for Sale'>
                                            <option value="ready">Ready To move</option>
                                            <option value="off">Off Plan</option>
                                        </optgroup>
                                        <option value="rent">Property For Rent</option>

                                    </select>
                                </div>

                                <div className="col-md-222 wrap">
                                    <select className="form-select py-3">
                                        <option defaultValue disabled>Location</option>
                                        <option value="all">Any Location</option>
                                        <option value="dxb">Dubai</option>
                                        <option value="shj">Sharjah</option>
                                        <option value="abh">Abu Dhabi</option>
                                        <option value="aj">Ajman</option>
                                        <option value="rsk">Ras Al Khayma</option>
                                        <option value="umq">Umm Al Quwain</option>
                                        <option value="fjh">Fujairah</option>
                                    </select>
                                </div>


                                <div className="col-md-222 wrap">
                                    <select className="form-select py-3">
                                        <option defaultValue disabled>Bads</option>
                                        <option value="all">All</option>
                                        <option value="studio">Studio</option>
                                        <option value="1bed">1BHK</option>
                                        <option value="2bed">2BHK</option>
                                        <option value="3bed">3BHK</option>
                                        <option value="4bed">4BHK</option>
                                        <option value="5bed">5BHK</option>
                                    </select>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-2">
                            <a className="btn btn-dark border-0 w-100 py-3" href='/all-properties'>Search</a>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter