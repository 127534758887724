import React from 'react';
import Navbar from '../components/navbar'
import Footer from '../components/footer'

const TermsAndConditions = () => {
    return (
        <div>
            <Navbar />
            <div className="buying-guide-container">
                <h1 className='mb-5'>Terms and Conditions</h1>
                <p className='mb-3'>
                    Please read these terms and conditions carefully before using MAK Luxury
                    Real Estate's services.
                </p>
                <h2 className='mb-3'>1. Acceptance of Terms</h2>
                <p className='mb-3'>
                    By using our services, you agree to be bound by these terms and
                    conditions. If you do not agree with any part of these terms, you may
                    not use our services.
                </p>
                <h2 className='mb-3'>2. Description of Services</h2>
                <p className='mb-3'>
                    Our services include but are not limited to providing listings of luxury
                    real estate properties, scheduling property viewings, facilitating
                    negotiations, and guiding clients through the closing process.
                </p>
                <h2 className='mb-3'>3. Privacy Policy</h2>
                <p className='mb-3'>
                    Our privacy policy governs the collection, use, and disclosure of
                    personal information provided by users of our services. By using our
                    services, you consent to the terms of our privacy policy.
                </p>
                <h2 className='mb-3'>4. Intellectual Property</h2>
                <p className='mb-3'>
                    All content included in our services, such as text, graphics, logos,
                    images, and software, is the property of MAK Luxury Real Estate and is
                    protected by international copyright laws.
                </p>
                <h2 className='mb-3'>5. Limitation of Liability</h2>
                <p className='mb-3'>
                    MAK Luxury Real Estate shall not be liable for any indirect, incidental,
                    special, or consequential damages arising out of or in connection with
                    the use of our services. This includes but is not limited to damages for
                    loss of profits, goodwill, use, data, or other intangible losses.
                </p>
                <p className='mb-3'>
                    In no event shall MAK Luxury Real Estate be liable for any damages
                    whatsoever resulting from the use or inability to use our services,
                    whether based on warranty, contract, tort, or any other legal theory,
                    and whether or not we have been advised of the possibility of such
                    damages.
                </p>
                <h2 className='mb-3'>6. Governing Law</h2>
                <p className='mb-3'>
                    These terms and conditions shall be governed by and construed in
                    accordance with the laws of Dubai. Any disputes arising from these terms
                    and conditions or the use of our services shall be subject to the
                    exclusive jurisdiction of the courts of Dubai.
                </p>
                <h2 className='mb-3'>7. Changes to Terms</h2>
                <p className='mb-3'>
                    MAK Luxury Real Estate reserves the right to modify these terms and
                    conditions at any time without prior notice. It is your responsibility
                    to review these terms periodically for changes. Your continued use of
                    our services after any modifications indicates your acceptance of the
                    updated terms and conditions.
                </p>
                <h2 className='mb-3'>8. Contact Us</h2>
                <p className='mb-3'>
                    If you have any questions or concerns about these terms and conditions,
                    please contact us for clarification.
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default TermsAndConditions;