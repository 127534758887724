import React from 'react'

function SmallHeader() {
  return (
    <div>
        <div class="container-fluid header p-0">
            <div class="row g-0 align-items-center flex-column-reverse flex-md-row">
                <div class="col-md-6 p-5 mt-lg-5">
                    <h1 class="display-5 animated fadeIn mb-4">About Us</h1> 
                        <nav aria-label="breadcrumb animated fadeIn">
                        <ol class="breadcrumb text-uppercase">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item"><a href="/">Pages</a></li>
                            <li class="breadcrumb-item text-body active" aria-current="page">About</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-md-6 animated fadeIn">
                    <video class="video-fluid-about" autoPlay loop muted  allowfullscreen>
                        <source src="/img/bg-vd.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>
        </div>


    </div>
  )
}

export default SmallHeader