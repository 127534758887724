import React from 'react'

function Footer() {
    return (
        <div>
            <div className="contaner-fluid-footer footer  wow fadeIn" data-wow-delay="0.1s">
                <ul className="bg-bubbles" id="bubbles">
                    <li className="bubble"></li>
                    <li className="bubble"></li>
                    <li className="bubble"></li>
                    <li className="bubble"></li>
                    <li className="bubble"></li>
                    <li className="bubble"></li>
                    <li className="bubble"></li>
                    <li className="bubble"></li>
                    <li className="bubble"></li>
                    <li className="bubble"></li>
                </ul>

                <div className="contaner-footer">

                    <div className="container py-5">
                        <div className="row g-5">
                            <div className="col-lg-3 col-md-6" style={{ justifyContent: 'center' }} >
                                <h5 className="text-footer mb-4">Get In Touch</h5>
                                <a href='https://www.google.com/maps/place/MAK+Luxury+Real+Estate/@25.1869036,55.2581728,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f697422b61b33:0x61dacaf45338de8c!8m2!3d25.1869036!4d55.2607477!16s%2Fg%2F11vst6p6p_?entry=ttu'> <p className="mb-2 text-footer"><i className="fa fa-map-marker-alt me-3"></i>Office 1303 The Regal Tower, Business Bay, Dubai , UAE</p></a>
                                <p className="mb-2 text-footer"><i className="fa fa-phone-alt me-3"></i>+971 58 109 0851</p>
                                <p className="mb-2 text-footer"><i className="fa fa-phone-alt me-3"></i>+971 55 994 1397</p>
                                <p className="mb-2 text-footer"><i className="fa fa-envelope me-3"></i>info@mak-luxury.com</p>

                                <div className="d-flex pt-2">

                                    <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/profile.php?id=61556295171946"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/mak_luxury_real_estate/"><i className="fab fa-instagram"></i></a>
                                    <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/mak-luxury-real-estate-uae/"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-outline-light btn-social" href="https://www.tiktok.com/@mak.luxury.real.e"><i className="fab fa-tiktok"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <h5 className="text-footer mb-4">Quick Links</h5>
                                <a className="btn btn-link text-footer" href="/about">About Us</a>
                                <a className="btn btn-link text-footer" href="/contact">Contact Us</a>
                                <a className="btn btn-link text-footer" href="/buyingGuid">Buying Guid</a>

                                <a className="btn btn-link text-footer" href="/terms">Terms & Condition</a>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <h5 className="text-footer mb-4">Properties</h5>
                                <a className="btn btn-link text-footer" href="/all-properties">For Sale Properties</a>
                                <a className="btn btn-link text-footer" href="/all-properties">For rent Properties</a>
                                <a className="btn btn-link text-footer" href="/all-properties">Dubai Off Plane Properties</a>
                                <a className="btn btn-link text-footer" href="/all-properties">Abu Dhabi Off Plane Properties</a>
                                <a className="btn btn-link text-footer" href="/all-properties">Sharja Off Plane Properties</a>

                            </div>

                            <div className="col-lg-3 col-md-6">

                                <img src="img/logo-footer.png" height={250} alt="" />

                            </div>

                        </div>
                    </div>

                    <div className="container">
                        <div className="copyright">
                            <div className="row">
                                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                    &copy; <a className="border-bottom" href="">MAK Luxury Real Estate </a>, All Right Reserved.
                                    Designed By <a className="border-bottom" href="https://vegadev.site">Vega Dev</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer