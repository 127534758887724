import React from 'react'

function Header() {
    return (
        <div>
            <div className="header wow fadeIn" data-wow-delay="0.5s">
                <video autoPlay muted loop id="bgVideo" >
                    <source src="/img/bg-vd.mp4" type="video/mp4" />
                </video>
                <div className="header-content">
                    <p className="welcome" >Welcome to MAK Luxury Real Estate</p>
                    <h1 className="header-title">AMONG THE BEST REAL ESTATE BROKERS IN THE UAE</h1>
                    <p className="headline-header">MAK Luxury Real Estate: Where Opulence Meets Unparalleled Services in the Heart of the UAE</p>
                    <a className="btn btn-glass" href='/all-properties'>Find your Place</a>
                </div>
            </div>
        </div>
    )
}

export default Header